import { Box, Button, Center, Container, Flex, Group, Paper, PasswordInput, Space, Stack, Text, TextInput, Title } from "@mantine/core";
import { CalendarBlank, CubeFocus, Envelope, LockKey } from "@phosphor-icons/react";

import { IconCrown } from "@tabler/icons-react";
import SemiScore from "../ScoreGauge/SemiScore";
import classes from "./LoginSection.module.css";
import { useForm } from "@mantine/form";
import { useDisclosure, useMounted } from "@mantine/hooks";
import { signIn } from "next-auth/react";
import { SearchParams } from "@syde/config/types";
import isEmpty from "lodash/isEmpty";

import queryString from "query-string";
import { useEffect } from "react";

export function LoginSection({ searchParams }: { searchParams: SearchParams }) {
  const mounted = useMounted();

  let _searchParams: any = searchParams;
  if (isEmpty(searchParams) && typeof window !== "undefined") {
    _searchParams = queryString.parse(window.location.search);
  }

  const [isLoading, { open: startLoading, close: stopLoading }] = useDisclosure(false);
  const [passwordVisible, { toggle: passwordToggle }] = useDisclosure(false);

  const form = useForm({
    initialValues: { email: "", password: "" },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "The email doesn't look valid"),
    },
  });

  useEffect(() => {
    if (_searchParams["error"] === "CredentialsSignin") {
      //   window.location.href = "/auth-error";
      form.setErrors({ email: "Invalid credentials / Something went wrong" });
    }
  }, []);

  const onLogin = async (values: any) => {
    const result = await signIn("credentials", {
      redirect: true,
      email: values.email,
      password: values.password,
      callbackUrl: "/model-rank",
    });
    console.log(result);
  };

  if (!mounted) return null;

  return (
    <Container size={"xl"} py={"md"} mih={"80vh"}>
      <Center py={80}>
        <Flex direction={"column"} align={"center"}>
          <Text size="xl" fw={500}>
            Login to your account
          </Text>
          <Text c="var(--text-sec-light)">Enter your details to login.</Text>
          <Space h={"xl"} />
          <form onSubmit={form.onSubmit(onLogin)}>
            <TextInput
              label="Email Address"
              //   leftSection={<Envelope size={20} />}
              placeholder=""
              w={{ base: 300, md: 392 }}
              {...form.getInputProps("email")}
            />

            <PasswordInput
              label="Password"
              //   leftSection={<LockKey size={20} />}
              onVisibilityChange={passwordToggle}
              visible={passwordVisible}
              placeholder=""
              mt={"md"}
              {...form.getInputProps("password")}
            />

            <Space h={"sm"} />

            <Group mt="md" grow>
              <Button type="submit" fullWidth loading={isLoading} loaderProps={{ type: "dots" }}>
                Login
              </Button>
            </Group>
          </form>
        </Flex>
      </Center>
    </Container>
  );
}
