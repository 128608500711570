"use client";
import { LoginSection } from "@syde/components/LoginSection/LoginSection";
import { Footer } from "@syde/components/nav/Footer/Footer";
import { Header } from "@syde/components/nav/Header/Header";
import { SearchParams } from "@syde/config/types";

export default function Box({ searchParams }: { searchParams: SearchParams }) {
  return (
    <>
      <Header />
      <LoginSection searchParams={searchParams} />
      <Footer />
    </>
  );
}
